import { createContext, useContext } from "react";
import { IAuthenticationContext } from "./types";

const authentication: IAuthenticationContext = {
  user: undefined,
  user_permissions: [],
  token: undefined,
  error: undefined,
  isPendingAuthentication: true,
};

export const AuthenticationContext = createContext(authentication);

export const useAuthentication = () => useContext(AuthenticationContext);
