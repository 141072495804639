import { Box, Flex, Stack } from "@chakra-ui/react";
import dcv, { Connection, ConnectionError, DisplayConfigError } from "dcv";
import log from "loglevel";
import { orientation } from "o9n";
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import Draggable from "react-draggable";
import { useTranslation } from "react-i18next";
import { useDebouncedCallback } from "use-debounce";
import useResizeObserver, { ObservedSize } from "use-resize-observer";
import { DragHandle } from "../../components/DragHandle";
import { useNotificationToast } from "../../hooks";
import { isIOS, isMobile } from "../../utils/browser-support";
import { ConnectionStateOverlay } from "../interactive-spectator/ConnectionStateOverlay";
import { RemoteUrlOpenerOverlay } from "../interactive-spectator/RemoteUrlOpenerOverlay";
import { StreamerControls } from "../StreamerControls";
import { StreamerRef } from "../types";
import { useStreamerContext } from "../useStreamerContext";
import { useDcvPreferences } from "./useDcvPreferences";

const LOG_LEVEL = dcv.LogLevel.INFO;

type DcvViewerProps = {
  dcvEndpoint: string;
};

type ConnectionState = RTCPeerConnectionState;

export const DcvViewer = forwardRef<StreamerRef, DcvViewerProps>(
  function DcvViewer({ dcvEndpoint }, ref) {
    const { t } = useTranslation();
    const toast = useNotificationToast();
    const containerRef = useRef<HTMLDivElement>(null);
    const SERVER_URL = dcvEndpoint;
    const [authenticated, setAuthenticated] = React.useState(false);
    const [dcvSessionId, setDcvSessionId] = React.useState("");
    const [authToken, setAuthToken] = React.useState("");
    const connection = React.useRef<dcv.Connection | null>(null);
    const authenticationInProgress = React.useRef(false);
    const auth = React.useRef<dcv.Authentication | null>(null);
    const {
      isConnected,
      setIsConnected,
      isAudioMuted,
      isMicrophoneMuted,
      isFullscreen,
      setIsFullscreen,
    } = useStreamerContext();
    const [dcvPreferences, setDcvPreferences] = useDcvPreferences();

    const [connectionState, setConnectionState] =
      useState<ConnectionState>("connecting");
    const [connectionError, setConnectionError] = useState<
      string | undefined
    >();

    useEffect(() => {
      if (connection.current && isConnected) {
        connection.current
          .requestDisplayConfig(dcvPreferences.highColorAccuracy)
          .catch((error: DisplayConfigError) => {
            log.error("Failed to set display config", error);
          });
        connection.current.setDisplayQuality(
          dcvPreferences.streamingMode === "quality" ? 70 : 20,
          90,
        );
      }
    }, [
      isConnected,
      dcvPreferences.highColorAccuracy,
      dcvPreferences.streamingMode,
    ]);

    useEffect(() => {
      // effect to unmute / mute microphone
      if (connection.current && isConnected) {
        connection.current.setMicrophone(!isMicrophoneMuted);
      }
    }, [isMicrophoneMuted, isConnected]);

    useEffect(() => {
      // effect to unmute / mute audio
      if (connection.current && isConnected) {
        connection.current.setVolume(isAudioMuted ? 0 : 100);
      }
    }, [isAudioMuted, isConnected]);

    useEffect(() => {
      // effect to enable fullscreen mode when the user wants to
      // on some devices, like mobile devices the real browser fullscreen has some limitations, e.g. on the type of interactions to be performed
      // special handling on iOS as fullscreen mode is not great there for interactivity
      if (isIOS) {
        if (isFullscreen) {
          // fullscreen mode is requested by the user
          // if we're not in fullscreen mode, we'll just maximize the video, but only if we're in landscape orientation (only for mobile)
          if (isMobile && !orientation.type.startsWith("landscape")) {
            // tell the user to turn the device
            toast({
              title: t("spectator.fullscreen.turn-to-enable-title"),
              description: t("spectator.fullscreen.turn-to-enable-description"),
              status: "info",
              duration: 5000,
            });
            setIsFullscreen(false);
          }
        } else {
          // should exit fullscreen mode as requested by the user
          if (isMobile && !orientation.type.startsWith("portrait")) {
            // tell the user to turn the device
            toast({
              title: t("spectator.fullscreen.turn-to-disable-title"),
              description: t(
                "spectator.fullscreen.turn-to-disable-description",
              ),
              status: "info",
              duration: 5000,
            });
          }
        }
        return;
      }

      const container = containerRef.current;
      if (isFullscreen) {
        // nothing to do if we're already in fullscreen mode
        if (document.fullscreenElement) {
          return;
        }

        if (!document.fullscreenElement && container) {
          container
            .requestFullscreen()
            .then(() => {
              log.debug("Entered fullscreen mode");
              return connection.current
                ? connection.current.requestResolution(
                    container.offsetWidth,
                    container.offsetHeight,
                  )
                : Promise.resolve();
            })
            .catch((error) => {
              log.error("Failed to enter fullscreen mode", error);
            });
        }
        document.onfullscreenchange = () => {
          if (!document.fullscreenElement) {
            // user exited fullscreen mode
            document.onkeydown = null;
            document.onkeyup = null;
            setIsFullscreen(false);
          }
        };
      } else {
        // nothing to do if we're not in fullscreen mode
        if (!document.fullscreenElement) {
          return;
        }

        // exit fullscreen mode as requested by the user
        document
          .exitFullscreen()
          .then(() => {
            log.debug("Exited fullscreen mode");
            return container && connection.current
              ? connection.current.requestResolution(
                  container.offsetWidth,
                  container.offsetHeight,
                )
              : Promise.resolve();
          })
          .catch((error) => {
            log.error("Failed to exit fullscreen mode", error);
          });
      }
    }, [isFullscreen, setIsFullscreen, t, toast]);

    useImperativeHandle(
      ref,
      () => ({
        copyFromRemote: () => {
          connection.current?.sendKeyboardShortcut([
            // see e.g. https://www.toptal.com/developers/keycode
            { key: "Control", location: 1 },
            { key: "c", location: 0 },
          ]);
        },
        pasteToRemote: () => {
          if (!connection.current) return;
          // sync clipboard, then try to paste
          if (connection.current.syncClipboards()) {
            connection.current.sendKeyboardShortcut([
              // see e.g. https://www.toptal.com/developers/keycode
              { key: "Control", location: 1 },
              { key: "v", location: 0 },
            ]);
          } else {
            log.warn("Failed to sync clipboards");
          }
        },
        onWindowChanged: (windowHandle: number) => {
          log.warn("Not implemented");
        },
      }),
      [],
    );

    const authenticate = useCallback(() => {
      dcv.setLogLevel(LOG_LEVEL);

      auth.current = dcv.authenticate(SERVER_URL, {
        promptCredentials: () => {
          // FIXME: no credentials required
        },
        error: () => {
          setAuthenticated(false);
          authenticationInProgress.current = false;
        },
        success: (_, result) => {
          var { sessionId, authToken } = { ...result[0] };

          setDcvSessionId(sessionId);
          setAuthToken(authToken);
          setAuthenticated(true);
          authenticationInProgress.current = true;
        },
      });
    }, [SERVER_URL]);

    React.useEffect(() => {
      if (!authenticated && authenticationInProgress.current === false) {
        authenticationInProgress.current = true;
        authenticate();
      }
    }, [authenticate, authenticated]);

    const handleDisconnect = useCallback(
      (
        connection: Connection,
        { code, message }: { code: number; message: string },
      ) => {
        log.debug("Disconneced from DCV");
        setConnectionState("disconnected");
        setIsConnected(false);

        // code 2 = normal closure --> we can reconnect
        if (code !== 2) {
          setAuthenticated(false);

          // retry authentication unless we explicitly disconnected
          if (connection) {
            auth.current?.retry();
          }
        }
      },
      [setIsConnected],
    );

    const connect = useCallback(
      async (
        dcvSessionId: string,
        authToken: string,
        highColorAccuracy: boolean = false,
      ) => {
        dcv.setLogLevel(LOG_LEVEL);

        try {
          setConnectionState("connecting");
          setConnectionError(undefined);

          connection.current = await dcv.connect({
            url: dcvEndpoint,
            sessionId: dcvSessionId,
            authToken: authToken,
            baseUrl: window.location.protocol + "//" + window.location.host,
            divId: "dcv-display",
            clientHiDpiScaling: true,
            highColorAccuracy,
            // required for High Color Accuracy
            enableWebCodecs: highColorAccuracy,
            callbacks: {
              firstFrame: (connection) => {
                log.debug("First frame received", connection);
                setIsConnected(true);
                setConnectionState("connected");
              },
              disconnect: handleDisconnect,
              clipboardEvent: log.debug,
              featuresUpdate: (_connection, features) => {
                log.debug("Features updated: ", features);

                if (features.includes("high-color-accuracy")) {
                  _connection
                    .queryFeature("high-color-accuracy")
                    .then((status) => {
                      if (highColorAccuracy && !status.enabled) {
                        toast({
                          colorScheme: "red",
                          title: t(
                            "streamer.dcv.high_color_accuracy_unsupported",
                          ),
                        });
                      }

                      setDcvPreferences((pref) => ({
                        ...pref,
                        highColorAccuracy: status.enabled,
                      }));
                      log.debug("high-color-accuracy queried", status);
                    });
                }
              },
            },
          });
          log.debug("Connection established!");
          const maxWidth =
            containerRef.current?.parentElement?.offsetWidth ?? 0;
          const maxHeight =
            containerRef.current?.parentElement?.offsetHeight ?? 0;
          log.debug("Max resolution: ", maxWidth, maxHeight);
        } catch (err: unknown) {
          setConnectionState("failed");
          setIsConnected(false);

          if (isConnectionError(err)) {
            log.debug("Connection failed with error " + err.message);
            setConnectionError(err.message);
          } else {
            log.debug("Connection failed with unknown error");
            setConnectionError("Connection failed");
          }
        }
      },
      [
        dcvEndpoint,
        handleDisconnect,
        setDcvPreferences,
        setIsConnected,
        t,
        toast,
      ],
    );

    // update resolution when the container size changes
    useResizeObserver({
      ref: containerRef,
      onResize: useDebouncedCallback((size: ObservedSize) => {
        log.debug("Container size changed: ", size);
        const width = size.width;
        const height = size.height;
        if (width && height) {
          log.debug("Desired resolution: ", width, height);
          connection.current?.requestResolution(width, height);
        }
      }, 333),
    });

    useEffect(() => {
      if (authenticated) {
        connect(dcvSessionId, authToken, dcvPreferences.highColorAccuracy);
      }

      return () => {
        if (connection.current) {
          log.debug("Disconnecting from DCV");
          connection.current.disconnect();
          connection.current = null;
        }
      };
    }, [
      dcvSessionId,
      authToken,
      authenticated,
      connect,
      dcvPreferences.highColorAccuracy,
    ]);

    return (
      <Flex
        justifyContent={"center"}
        alignItems={"center"}
        flexDirection={"column"}
        flexGrow={1}
        ref={containerRef}
        position={"relative"}
      >
        <Box
          id="dcv-display"
          position={"absolute"}
          left={"50%"}
          top={"50%"}
          right={0}
          bottom={0}
          transform={"translate(-50%, -50%)"}
        />

        <ConnectionStateOverlay
          isConnected={false}
          connectionState={connectionState}
          connectionError={connectionError}
          display={isConnected ? "none" : "flex"}
          position={isConnected ? "absolute" : "relative"}
        />

        <RemoteUrlOpenerOverlay
          position={"absolute"}
          top={0}
          left={0}
          right={0}
          bottom={0}
        />

        {isFullscreen ? (
          <Draggable
            bounds="body"
            handle=".handle"
            axis={isMobile ? "y" : "x"}
            defaultPosition={{ y: isMobile ? -50 : 0, x: 0 }}
          >
            <Stack
              position="absolute"
              right={0}
              bottom={0}
              padding={"1"}
              borderRadius={"4"}
              bgColor={"backgroundAlpha.700"}
              zIndex={1}
              direction={isMobile ? "column" : "row"}
            >
              <DragHandle
                className="handle"
                onTouchEnd={(e) => e.stopPropagation()}
                onTouchStart={(e) => e.stopPropagation()}
              />
              <StreamerControls variant={"solid"} />
            </Stack>
          </Draggable>
        ) : null}
      </Flex>
    );
  },
);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function isConnectionError(err: any): err is ConnectionError {
  return "message" in err && "code" in err;
}
