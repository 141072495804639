import {
  Button,
  ButtonGroup,
  ButtonGroupProps,
  Heading,
  HStack,
  Icon,
  IconButton,
  Image,
  Link,
  Menu,
  MenuButton,
  MenuDivider,
  MenuGroup,
  MenuItem,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  Popover,
  PopoverAnchor,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverFooter,
  Stack,
  StackProps,
  useDisclosure,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import {
  FaPaste as PasteIcon,
  FaEllipsisV as StreamingMenuIcon,
} from "react-icons/fa";
import { useLocalStorage } from "usehooks-ts";
import {
  AudioMutedIcon,
  CopyIcon,
  ExternalLinkIcon,
  MultiWindowIcon,
} from "../components/icons";
import { selectIsCloudRenderedVRSession } from "../features/sessionSlice";
import { useApplicationForSessionQuery, useAppSelector } from "../hooks";
import { AudioButton } from "./interactive-spectator/AudioButton";
import { FullscreenButton } from "./interactive-spectator/FullscreenButton";
import { MicrophoneButton } from "./interactive-spectator/MicrophoneButton";
import { useRemoteUrls } from "./interactive-spectator/useRemoteUrls";
import { useStreamerContext } from "./useStreamerContext";

export function StreamerControls({
  variant = "outline",
  direction,
}: Pick<ButtonGroupProps, "variant"> & Pick<StackProps, "direction">) {
  const {
    toggleMicrophone: toggleAudioInput,
    toggleAudioOutput,
    toggleFullscreen,
    windows,
    isMicrophoneMuted,
    isAudioMuted,
    isAudioOutputBlockedByBrowserPolicy,
    setIsAudioOutputBlockedByBrowserPolicy,
    setIsAudioMuted,
    isFullscreen,
    onWindowChanged,
    copyToClipboard,
    pasteFromClipboard,
    isConnected,
  } = useStreamerContext();
  const { data: application } = useApplicationForSessionQuery();
  const remoteUrls = useRemoteUrls(application?.id);
  const { t } = useTranslation();
  const isCloudRenderedVRSession = useAppSelector(
    selectIsCloudRenderedVRSession,
  );
  const [
    isBlockedAudioOutputIgnoredByUser,
    setIsBlockedAudioOutputIgnoredByUser,
  ] = useLocalStorage<boolean>("isBlockedAudioOutputIgnoredByUser", false);
  const audioOutputBlockedByBrowserPolicyDisclosure = useDisclosure({
    isOpen:
      !isBlockedAudioOutputIgnoredByUser &&
      isAudioOutputBlockedByBrowserPolicy &&
      isAudioMuted,
    onClose: () => {
      setIsAudioOutputBlockedByBrowserPolicy(false);
      setIsBlockedAudioOutputIgnoredByUser(true);
    },
  });

  return (
    <ButtonGroup
      size="sm"
      variant={variant}
      isDisabled={!isConnected}
      flexDirection={direction}
      display={"flex"}
      spacing={0}
      alignItems={"center"}
      gap={0.5}
    >
      <Popover
        closeOnBlur={false}
        closeOnEsc={false}
        placement="bottom"
        {...audioOutputBlockedByBrowserPolicyDisclosure}
      >
        <PopoverAnchor>
          <AudioButton
            isAudioMuted={isAudioMuted}
            toggleAudioOutput={toggleAudioOutput}
          />
        </PopoverAnchor>
        <PopoverContent>
          <PopoverArrow />
          <PopoverBody fontSize={"sm"}>
            {t("spectator.audio.blocked_by_browser_policy")}
          </PopoverBody>
          <PopoverFooter>
            <HStack justifyContent={"end"}>
              <Button
                leftIcon={<Icon as={AudioMutedIcon} />}
                size="xs"
                onClick={() => {
                  setIsAudioMuted(false);
                  audioOutputBlockedByBrowserPolicyDisclosure.onClose();
                }}
              >
                {t("spectator.audio.unmute")}
              </Button>
              <Button
                size="xs"
                onClick={audioOutputBlockedByBrowserPolicyDisclosure.onClose}
                variant={"ghost"}
              >
                {t("spectator.audio.ignore")}
              </Button>
            </HStack>
          </PopoverFooter>
        </PopoverContent>
      </Popover>
      {!isCloudRenderedVRSession && (
        <MicrophoneButton
          isMicrophoneMuted={isMicrophoneMuted}
          toggleAudioInput={toggleAudioInput}
        />
      )}
      {windows.length > 1 && (
        <Menu placement="top">
          <MenuButton as={IconButton} icon={<MultiWindowIcon />} />
          <MenuList>
            <MenuOptionGroup
              title={t("spectator.windows.title")}
              type="radio"
              value={windows
                .find((window) => window.isActive)
                ?.handle.toString()}
              onChange={(e) => onWindowChanged(Number(e))}
            >
              {windows.map(
                ({ title, handle, isActive, thumbnail: thumbnailBase64 }) => (
                  <MenuItemOption
                    key={handle}
                    fontWeight={isActive ? "bold" : "normal"}
                    value={handle.toString()}
                  >
                    <TargetWindowThumbnail
                      base64Data={thumbnailBase64}
                      title={title}
                    />
                  </MenuItemOption>
                ),
              )}
            </MenuOptionGroup>
          </MenuList>
        </Menu>
      )}
      <Menu>
        <MenuButton as={IconButton} icon={<StreamingMenuIcon />} />
        <MenuList>
          <MenuItem
            icon={<CopyIcon />}
            onClick={copyToClipboard}
            command="Ctrl + C"
          >
            {t("spectator.actions.copy")}
          </MenuItem>
          <MenuItem
            icon={<PasteIcon />}
            onClick={pasteFromClipboard}
            command="Ctrl + V"
          >
            {t("spectator.actions.paste")}
          </MenuItem>
          {remoteUrls.length > 0 && (
            <>
              <MenuDivider />
              <MenuGroup title={t("spectator.remoteUrls.title")}>
                {remoteUrls.map(({ url }, idx) => (
                  <MenuItem
                    key={url + idx}
                    as={Link}
                    href={url}
                    isExternal
                    title={url}
                  >
                    <Icon boxSize={3} as={ExternalLinkIcon} marginRight={2} />{" "}
                    {limitStringLength(url, 40)}
                  </MenuItem>
                ))}
              </MenuGroup>
            </>
          )}
        </MenuList>
      </Menu>
      <FullscreenButton
        isFullscreen={isFullscreen}
        toggleFullscreen={toggleFullscreen}
      />
    </ButtonGroup>
  );
}

function limitStringLength(str: string, maxLength: number) {
  return str.length > maxLength ? str.slice(0, maxLength) + "..." : str;
}

function TargetWindowThumbnail({
  base64Data,
  title,
}: {
  base64Data: string;
  title: string;
}) {
  return (
    <Stack spacing={1}>
      <Heading as="h4" size="xs">
        {title}
      </Heading>
      <Image
        src={`data:image/png;base64,${base64Data}`}
        alt="Thumbnail"
        className="thumbnail"
      />
    </Stack>
  );
}
