import { debug, error } from "loglevel";
import { useCallback } from "react";
import { generatePath, useNavigate } from "react-router-dom";
import { SessionRequestPendingIndicator } from "../../components";
import { RegionNotAvailableError } from "../../components/RegionNotAvailableError";
import { RegionNotSupportedWarning } from "../../components/RegionNotSupportedWarning";
import { useRegionNotSupportedWarning } from "../../hooks/useRegionNotSupportedWarning";
import { SessionType } from "../../session/types";
import { requestSession } from "../../signalR";
import { routes } from "../../utils/routes";
import { getUniqueIdForApplication } from "../../utils/unique-ids";
import { useLaunchPageContext } from "../LaunchPageContext";

export function CloudLaunchBrowser() {
  const navigate = useNavigate();

  const {
    application,
    applicationBuildId,
    organization,
    launchArgs,
    forceColdVm,
    vmImage,
    vmSize,
    renderRegion,
    virtualMachineId,
    debugModeEnabled,
    streamer,
  } = useLaunchPageContext();

  const navigateBack = useCallback(
    () =>
      navigate(
        generatePath(routes.application.details, {
          id: application.id,
        }),
      ),
    [application.id, navigate],
  );

  const { noValidRegion, alertProps } = useRegionNotSupportedWarning({
    applicationBuildId: applicationBuildId,
    requestedVmSize: vmSize,
    requestedRegion: renderRegion,
    onRejected: navigateBack,
    onAccepted: useCallback(
      (selectedRegion) => {
        if (!selectedRegion?.region?.name) {
          return;
        }
        debug("launching in cloud with target browser");

        requestSession({
          appId: applicationBuildId,
          type: SessionType.CloudRenderedNonVR,
          deviceIdentifier: getUniqueIdForApplication(),
          launchArguments: launchArgs,
          organizationId: organization.id,
          renderRegion: selectedRegion.region?.name,
          vmSize: selectedRegion.vmSize,
          forceColdVm,
          // no VR streaming encryption for browser sessions, see https://github.com/Innoactive/Session-Management/pull/534
          encryptVrStream: undefined,
          vmImage,
          virtualMachineId,
          debugModeEnabled,
          // to enable DCV streaming gateway for browser sessions
          withStreamingGateway:
            selectedRegion.region.supportsStreamingGateway &&
            streamer === "dcv",
        })
          .then(() => debug("Starting browser-based session"))
          .catch(error);
      },
      [
        applicationBuildId,
        debugModeEnabled,
        forceColdVm,
        launchArgs,
        organization.id,
        streamer,
        virtualMachineId,
        vmImage,
      ],
    ),
  });

  return (
    <>
      {!noValidRegion && <SessionRequestPendingIndicator />}
      <RegionNotSupportedWarning
        applicationId={application.id}
        {...alertProps}
      />
      <RegionNotAvailableError isOpen={noValidRegion} onClose={navigateBack} />
    </>
  );
}
