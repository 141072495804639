import {
  Icon,
  IconButton,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { ConfigurationIcon } from "../../components/icons";
import InteractiveSpectatorSettingsManager from "./InteractiveSpectatorSettingsManager";

export function InteractiveSpectatorPreferencesButton() {
  const { t } = useTranslation();

  return (
    <Popover>
      <PopoverTrigger>
        <IconButton
          icon={<Icon as={ConfigurationIcon} />}
          aria-label={t("streamer.interactive_spectator.preferences.change")}
          size="sm"
          variant="ghost"
        />
      </PopoverTrigger>
      <PopoverContent>
        <PopoverArrow />
        <PopoverCloseButton />
        <PopoverHeader>
          {t("streamer.interactive_spectator.preferences.title")}
        </PopoverHeader>
        <PopoverBody>
          <InteractiveSpectatorSettingsManager />
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
}
