import log from "loglevel";
import { useEffect } from "react";
import { selectSessionState } from "../../features/sessionSlice";
import { useAppSelector } from "../../hooks";
import { sendSessionActivity } from "../../signalR";

export function DcvViewerSessionActivitySender() {
  const session = useAppSelector(selectSessionState);

  // Send user activity ping every 5 seconds while the page is visible
  useEffect(() => {
    const sessionId = session.id;
    if (!sessionId) {
      return;
    }

    if (!session.appLaunched) {
      return;
    }

    const sendActivityPing = async () => {
      try {
        await sendSessionActivity(sessionId);
      } catch (error) {
        log.error("Failed to send activity ping", error);
      }
    };

    const intervalId = setInterval(() => {
      if (document.visibilityState === "visible") {
        sendActivityPing();
      }
    }, 5000);

    return () => clearInterval(intervalId);
  }, [session.appLaunched, session.id]);

  return null;
}
