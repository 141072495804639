import { Tab, TabList, TabPanel, TabPanels, Tabs } from "@chakra-ui/react";
import { useMemo } from "react";
import { partition } from "remeda";
import { XRPlatformsDisplay } from "../../components/XRPlatformDisplay";
import {
  Application,
  ApplicationBuildId,
  ApplicationLaunchConfiguration,
  LaunchableApplicationLaunchConfiguration,
} from "../../hooks/types";
import { LazyApplicationBuildChangelog } from "./LazyApplicationBuildChangelog";

export function ApplicationChangelogs({ app }: { app: Application }) {
  // for each launch configuration supported by this application, load the respective build(s) to get the changelog
  const launchConfigurations =
    app.launch_configurations.filter<LaunchableApplicationLaunchConfiguration>(
      (
        launchConfiguration,
      ): launchConfiguration is LaunchableApplicationLaunchConfiguration =>
        !!launchConfiguration.application_build,
    );
  const launchConfigurationsByBuild = useMemo(() => {
    // merge windows launch configurations if they use the same build
    const [windowsLaunchConfigurations, otherLaunchConfigurations] = partition(
      launchConfigurations,
      ({ xr_platform: type }) => type.startsWith("win"),
    );

    const result: [
      ApplicationLaunchConfiguration["xr_platform"][],
      ApplicationBuildId,
    ][] = [];
    // merge the windows xr platforms if they share the same build
    if (
      windowsLaunchConfigurations.length > 1 &&
      windowsLaunchConfigurations
        .map(({ application_build }) => application_build)
        .every(
          (buildId) =>
            buildId === windowsLaunchConfigurations[0].application_build,
        )
    ) {
      result.push([
        windowsLaunchConfigurations.map(({ xr_platform: type }) => type),
        windowsLaunchConfigurations[0].application_build,
      ]);
    } else {
      windowsLaunchConfigurations
        .sort((a, b) => a.xr_platform.localeCompare(b.xr_platform))
        .forEach(({ xr_platform: type, application_build }) => {
          result.push([[type], application_build]);
        });
    }

    // re-add the other launch configurations
    otherLaunchConfigurations
      .sort((a, b) => a.xr_platform.localeCompare(b.xr_platform))
      .forEach(({ xr_platform: type, application_build }) => {
        result.push([[type], application_build]);
      });

    return result;
  }, [launchConfigurations]);

  // if there's only one launch configuration, just show it's changelog
  if (launchConfigurationsByBuild.length === 1) {
    return (
      <LazyApplicationBuildChangelog
        buildId={launchConfigurationsByBuild[0][1]}
      />
    );
  }

  return (
    <Tabs isLazy>
      <TabList>
        {launchConfigurationsByBuild.map(([xrPlatforms]) => (
          <Tab key={xrPlatforms.join("_")}>
            <XRPlatformsDisplay xrPlatforms={xrPlatforms} />
          </Tab>
        ))}
      </TabList>

      <TabPanels>
        {launchConfigurationsByBuild.map(([xrPlatforms, buildId]) => (
          <TabPanel key={xrPlatforms.join("_")}>
            <LazyApplicationBuildChangelog buildId={buildId} />
          </TabPanel>
        ))}
      </TabPanels>
    </Tabs>
  );
}
