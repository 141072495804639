import { selectSessionState } from "../features/sessionSlice";
import { useAppSelector } from "../hooks";
import { DcvViewerSessionActivitySender } from "./dcv/DcvSessionActivitySender";
import { DcvViewer } from "./dcv/DcvViewer";
import { InteractiveSpectator } from "./interactive-spectator/InteractiveSpectator";
import { type StreamerType } from "./utils";
import { useStreamerContext } from "./useStreamerContext";

export function Streamer({ streamerType }: { streamerType: StreamerType }) {
  const session = useAppSelector(selectSessionState);
  const { streamerRef } = useStreamerContext();

  return (
    <>
      {streamerType === "dcv" && session.streamingGatewayEndpoint ? (
        <>
          <DcvViewerSessionActivitySender />
          <DcvViewer
            dcvEndpoint={`https://${session.streamingGatewayEndpoint}`}
            ref={streamerRef}
          />
        </>
      ) : session.ipAddress && streamerType === "innoactive" ? (
        <InteractiveSpectator
          sessionId={session.id}
          host={session.ipAddress}
          height="100%"
          ref={streamerRef}
        />
      ) : null}
    </>
  );
}
