import {
  FormControl,
  FormHelperText,
  FormLabel,
  HStack,
  Stack,
  Switch,
  ToastId,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import {
  useEncryptVrStreamSetting,
  useNotificationToast,
  useOrganizationCloudRenderingPolicies,
} from "../hooks";
import { useUpdateUserPreferencesMutation } from "../hooks/useUpdateUserPreferencesMutation";
import { useUserPreferencesQuery } from "../hooks/useUserPreferences";

let activeToastId: ToastId | undefined;
export function StreamingSecurityPreferences() {
  const { t } = useTranslation();
  const toast = useNotificationToast();
  const { data: policies, isLoading: isPolicyLoading } =
    useOrganizationCloudRenderingPolicies();
  const canUserChangeEncryptionSetting =
    policies?.cloudXREncryption === "OptIn" ||
    policies?.cloudXREncryption === "OptOut";
  const encryptVrStreamSetting = useEncryptVrStreamSetting();
  const { data: userPreference, isLoading: isUserPreferencesLoading } =
    useUserPreferencesQuery();
  const updateUserPreferencesMutation = useUpdateUserPreferencesMutation();

  return (
    <FormControl as="fieldset">
      <Stack spacing={4} paddingTop={4}>
        <HStack>
          <Switch
            id="encrypt-vr-stream"
            isChecked={encryptVrStreamSetting}
            isDisabled={
              isPolicyLoading ||
              !canUserChangeEncryptionSetting ||
              isUserPreferencesLoading ||
              updateUserPreferencesMutation.isPending
            }
            onChange={(evt) => {
              if (!userPreference) return;

              updateUserPreferencesMutation.mutate(
                { encryptVrStream: evt.target.checked },
                {
                  onSuccess: () => {
                    activeToastId && toast.close(activeToastId);
                    activeToastId = toast({
                      title: t("streamingPreferences.dialog.savedTitle"),
                      description: t(
                        "streamingPreferences.dialog.savedSecurity",
                      ),
                      status: "success",
                    });
                  },
                  onError: () => {
                    activeToastId && toast.close(activeToastId);
                    activeToastId = toast({
                      title: t("streamingPreferences.dialog.errorTitle"),
                      description: t("streamingPreferences.dialog.error"),
                      status: "error",
                    });
                  },
                },
              );
            }}
          />
          <FormLabel htmlFor="encrypt-vr-stream" margin={0}>
            {t("streamingPreferences.security.encrypt_vr_stream")}
          </FormLabel>
        </HStack>
        <FormHelperText marginTop={0}>
          {canUserChangeEncryptionSetting
            ? encryptVrStreamSetting === false
              ? t(
                  "streamingPreferences.security.encrypt_vr_stream_help_text_disabled",
                )
              : t(
                  "streamingPreferences.security.encrypt_vr_stream_help_text_enabled",
                )
            : t(
                "streamingPreferences.security.policies_deny_encrypt_vr_stream_change",
              )}
        </FormHelperText>
      </Stack>
    </FormControl>
  );
}
