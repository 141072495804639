import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormLabel,
  Heading,
  Icon,
  Stack,
  Text,
  ToastId,
} from "@chakra-ui/react";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { FaMapMarker } from "react-icons/fa";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  selectCloudRendering,
  selectLatencyTestCompleted,
} from "../features/cloudRenderingSlice";
import {
  useAppSelector,
  useCloudRenderingRegionsQuery,
  useNotificationToast,
} from "../hooks";
import { useCloudProviders } from "../hooks/useCloudProviders";
import { useCloudRenderingRegion } from "../hooks/useCloudRenderingRegionsQuery";
import { useUpdateUserPreferencesMutation } from "../hooks/useUpdateUserPreferencesMutation";
import { useUserPreferencesQuery } from "../hooks/useUserPreferences";
import { routes } from "../utils/routes";
import { CloudRenderingRegionsMap } from "./CloudRenderingRegionsMap";
import { CloudRenderingRegionsTable } from "./CloudRenderingRegionsTable";

let activeToastId: ToastId | undefined;
export function CloudRenderingRegionPreferences({
  onClose,
}: {
  onClose: () => void;
}) {
  const { t } = useTranslation();
  const toast = useNotificationToast();
  const [enabledCloudProviders, setEnabledCloudProviders] = useState<{
    [id: string]: boolean;
  }>({});
  const { data: userPreference, isLoading: isUserPreferencesLoading } =
    useUserPreferencesQuery();
  const cloudRendering = useAppSelector(selectCloudRendering);
  const preferredRegionDetailsQuery = useCloudRenderingRegion(
    userPreference?.preferredRenderRegion ?? undefined,
  );
  const preferredRegionDetails = preferredRegionDetailsQuery.data;
  const manualMode = useMemo(
    () => !!userPreference?.preferredRenderRegion,
    [userPreference?.preferredRenderRegion],
  );
  const cloudProviders = useCloudProviders();
  const {
    data: regions,
    isFetched: areRegionsFetched,
    isLoading: isLoadingRegions,
  } = useCloudRenderingRegionsQuery({
    appIds: cloudRendering.applicationBuildId
      ? [cloudRendering.applicationBuildId]
      : undefined,
  });
  const defaultRegion = useMemo(
    () => (regions ?? []).filter((r) => r.isEnabled).at(0)?.name,
    [regions],
  );
  const updateUserPreferencesMutation = useUpdateUserPreferencesMutation();
  const isServerLocationWizardCompleted = useSelector(
    selectLatencyTestCompleted,
  );

  // ensure all cloud providers are initially enabled
  useEffect(() => {
    setEnabledCloudProviders((enabledCloudProviders) =>
      Object.fromEntries(
        cloudProviders.map((cloudProvider) => [
          cloudProvider.name,
          enabledCloudProviders[cloudProvider.name] ?? true,
        ]),
      ),
    );
  }, [cloudProviders]);

  // get filtered list of cloud rendering regions based on disabled cloud providers
  const filteredCloudRenderingRegions = useMemo(() => {
    return regions?.filter((c) => enabledCloudProviders[c.cloudProvider]);
  }, [enabledCloudProviders, regions]);

  const updatePreferredCloudRenderingRegion = (region: string | undefined) => {
    // when switching to automated mode, re-enable all cloud providers
    region === undefined &&
      setEnabledCloudProviders(
        cloudProviders.reduce<{ [region: string]: boolean }>(
          (all, cloudProvider) => ({ ...all, [cloudProvider.name]: true }),
          {},
        ),
      );
    if (!userPreference) return;

    updateUserPreferencesMutation.mutate(
      {
        preferredRenderRegion: region ?? null,
      },
      {
        onSuccess: () => {
          activeToastId && toast.close(activeToastId);
          activeToastId = toast({
            title: t("streamingPreferences.dialog.savedTitle"),
            description: t("streamingPreferences.dialog.saved"),
            status: "success",
          });
        },
        onError: () => {
          activeToastId && toast.close(activeToastId);
          activeToastId = toast({
            title: t("streamingPreferences.dialog.errorTitle"),
            description: t("streamingPreferences.dialog.error"),
            status: "error",
          });
        },
      },
    );
  };

  const isLoading = useMemo(() => {
    return (
      updateUserPreferencesMutation.isPending ||
      isUserPreferencesLoading ||
      isLoadingRegions
    );
  }, [
    updateUserPreferencesMutation.isPending,
    isUserPreferencesLoading,
    isLoadingRegions,
  ]);

  return (
    <Stack spacing={5}>
      <Text>{t("streamingPreferences.dialog.explanation")}</Text>
      <Button
        as={Link}
        to={routes.serverLocationWizard}
        leftIcon={<Icon as={FaMapMarker} boxSize={3} />}
        colorScheme={isServerLocationWizardCompleted ? undefined : "brand"}
        onClick={onClose}
      >
        {t("details.stream_preferences_wizard")}
      </Button>
      <Button
        onClick={() => {
          return updatePreferredCloudRenderingRegion(
            manualMode ? undefined : defaultRegion,
          );
        }}
        isDisabled={isLoading}
        variant={manualMode ? "solid" : "ghost"}
        colorScheme={
          manualMode && isServerLocationWizardCompleted ? "brand" : undefined
        }
      >
        {manualMode
          ? t("streamingPreferences.dialog.automaticModeLabel")
          : t("streamingPreferences.dialog.manualModeLabel")}
      </Button>

      {manualMode && (
        <>
          <Divider />
          {!preferredRegionDetails && preferredRegionDetailsQuery.isFetched && (
            <Alert status="warning">
              <AlertIcon alignSelf={"start"} />
              <Stack paddingLeft={2}>
                <AlertTitle>
                  {t("streamingPreferences.preferred_region_unavailable")}
                </AlertTitle>
                <AlertDescription>
                  {t(
                    "streamingPreferences.preferred_region_unavailable_details",
                    {
                      preferredRegion: userPreference?.preferredRenderRegion,
                    },
                  )}
                </AlertDescription>
              </Stack>
            </Alert>
          )}
          {areRegionsFetched &&
            regions?.find(
              (region) => region.name === userPreference?.preferredRenderRegion,
            ) === undefined && (
              <Alert status="warning">
                <AlertIcon alignSelf={"start"} />
                <Stack paddingLeft={2}>
                  <AlertTitle>
                    {t("streamingPreferences.preferred_region_unsupported")}
                  </AlertTitle>
                  <AlertDescription>
                    {t(
                      "streamingPreferences.preferred_region_unsupported_details",
                      {
                        preferredRegion: preferredRegionDetails?.displayName,
                      },
                    )}
                  </AlertDescription>
                </Stack>
              </Alert>
            )}
          <FormControl>
            <FormLabel htmlFor="cloudProvider" fontWeight={"bold"}>
              {t("streamingPreferences.dialog.preferredCloudProvider")}
            </FormLabel>
            <Stack direction="row" spacing={4}>
              {cloudProviders?.map((cloudProvider) => (
                <Checkbox
                  isDisabled={isLoading}
                  key={cloudProvider.name}
                  value={cloudProvider.name}
                  isChecked={enabledCloudProviders[cloudProvider.name]}
                  onChange={(e) => {
                    setEnabledCloudProviders({
                      ...enabledCloudProviders,
                      [e.target.value]: e.target.checked,
                    });
                  }}
                >
                  {cloudProvider.displayName}
                </Checkbox>
              ))}
            </Stack>
          </FormControl>
          {/* show warning if preferred region is not available */}

          <Stack>
            <Heading size={"sm"}>
              {t("streamingPreferences.dialog.regions")}
            </Heading>
            <CloudRenderingRegionsTable
              regions={filteredCloudRenderingRegions ?? []}
              preferredCloudRenderRegionName={
                userPreference?.preferredRenderRegion ?? undefined
              }
              isLoading={isLoading}
              setCloudRenderingRegion={(newRegion) =>
                updatePreferredCloudRenderingRegion(newRegion?.name)
              }
            />
          </Stack>
          <CloudRenderingRegionsMap
            regions={filteredCloudRenderingRegions}
            preferredCloudRenderingRegionName={
              userPreference?.preferredRenderRegion ?? undefined
            }
            setCloudRenderingRegion={updatePreferredCloudRenderingRegion}
            isLoading={isLoading}
          />
        </>
      )}
    </Stack>
  );
}
