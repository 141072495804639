import { createContext, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNotificationToast } from "../hooks";
import { StreamerRef, TargetWindow } from "./types";

interface IStreamerContext {
  streamerRef: React.RefObject<StreamerRef>;
  /**
   * Whether the audio is muted or not
   */
  isAudioMuted: boolean;
  /**
   * Set the audio muted state
   */
  setIsAudioMuted: (muted: boolean) => void;
  /**
   * Toggle the audio output
   */
  toggleAudioOutput: () => void;
  /**
   * Whether the microphone is muted or not
   */
  isMicrophoneMuted: boolean;
  /**
   * Toggles the audio input (microphone)
   */
  toggleMicrophone: () => void;
  /**
   * Whether the streamer is shown fullscreen or not
   */
  isFullscreen: boolean;
  /**
   * Set the fullscreen state
   * @param fullscreen desired fullscreen state
   */
  setIsFullscreen: (fullscreen: boolean) => void;
  /**
   * Toggles the fullscreen state
   */
  toggleFullscreen: () => void;
  /**
   * The list of windows that can be streamed / switched to
   */
  windows: TargetWindow[];
  /**
   * The currently selected window handle
   */
  selectedWindowHandle?: TargetWindow["handle"];
  /**
   * Set the list of windows when received from the streamer
   * @param windows the list of windows
   */
  setWindows: (windows: TargetWindow[]) => void;
  /**
   * Called when the window should be changed
   * @param windowHandle the new window handle
   */
  onWindowChanged: (windowHandle: number) => void;
  /**
   * Whether the streamer is connected to the remote server
   */
  isConnected: boolean;
  /**
   * Set the connected state
   * @param connected the new connected state
   */
  setIsConnected: (connected: boolean) => void;
  /**
   * Sends the local clipboard content to the remote server and pastes it there
   */
  pasteFromClipboard: () => void;
  /**
   * Triggers the copy to clipboard action on the remote server and writes the content to the local clipboard
   */
  copyToClipboard: () => void;
  /**
   * Whether the audio output is blocked by the browser policy.
   */
  isAudioOutputBlockedByBrowserPolicy: boolean;
  /**
   * Set the audio output blocked by browser policy state
   */
  setIsAudioOutputBlockedByBrowserPolicy: (blocked: boolean) => void;
}

export const StreamerContext = createContext<IStreamerContext>(
  {} as IStreamerContext,
);

export function StreamerContextProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const { t } = useTranslation();
  const toast = useNotificationToast();
  const [isConnected, setIsConnected] = useState(false);
  const [isFullscreen, setIsFullscreen] = useState(false);
  // per default, we want to enable the audio output
  const [isAudioMuted, setIsAudioMuted] = useState(false);
  const [
    isAudioOutputBlockedByBrowserPolicy,
    setIsAudioOutputBlockedByBrowserPolicy,
  ] = useState(false);
  const [isMicrophoneMuted, setIsMicrophoneMuted] = useState(true);
  const [windows, setWindows] = useState<TargetWindow[]>([]);
  const streamerRef = useRef<StreamerRef | null>(null);

  return (
    <StreamerContext.Provider
      value={{
        streamerRef,
        isAudioMuted,
        setIsAudioMuted,
        toggleAudioOutput: () => setIsAudioMuted((muted) => !muted),
        isAudioOutputBlockedByBrowserPolicy,
        setIsAudioOutputBlockedByBrowserPolicy,
        isMicrophoneMuted,
        toggleMicrophone: () => setIsMicrophoneMuted((muted) => !muted),
        isFullscreen,
        setIsFullscreen,
        toggleFullscreen: () => setIsFullscreen((fullscreen) => !fullscreen),
        onWindowChanged: (windowHandle: number) =>
          streamerRef.current?.onWindowChanged(windowHandle),
        copyToClipboard: () => streamerRef.current?.copyFromRemote(),
        pasteFromClipboard: async () => {
          try {
            const content = await navigator.clipboard.readText();
            streamerRef.current?.pasteToRemote(content);
          } catch (err) {
            toast({
              title: t("spectator.clipboard.error.missing-permission-title"),
              description: t(
                "spectator.clipboard.error.missing-permission-description",
              ),
              status: "warning",
            });
          }
        },
        windows,
        setWindows,
        isConnected,
        setIsConnected,
      }}
    >
      {children}
    </StreamerContext.Provider>
  );
}
