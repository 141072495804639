import {
  Icon,
  IconButton,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { ConfigurationIcon } from "../../components/icons";
import DcvSettingsManager from "./DcvSettings";

export function DcvPreferencesButton() {
  const { t } = useTranslation();

  return (
    <Popover>
      <PopoverTrigger>
        <IconButton
          icon={<Icon as={ConfigurationIcon} />}
          aria-label={t("streamer.dcv.preferences.change")}
          size="sm"
          variant="ghost"
        />
      </PopoverTrigger>
      <PopoverContent>
        <PopoverArrow />
        <PopoverCloseButton />
        <PopoverHeader>{t("streamer.dcv.preferences.title")}</PopoverHeader>
        <PopoverBody>
          <DcvSettingsManager />
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
}
