import {
  FormControl,
  FormHelperText,
  FormLabel,
  HStack,
  Stack,
  Switch,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { useInteractiveSpectatorPreferences } from "./useInteractiveSpectatorPreferences";

export default function InteractiveSpectatorSettingsManager() {
  const { t } = useTranslation();
  const [interactiveSpectatorPreferences, setInteractiveSpectatorPreferences] =
    useInteractiveSpectatorPreferences();

  return (
    <Stack spacing={6}>
      <FormControl>
        <FormLabel fontWeight={"semibold"}>
          {t("streamer.interactive_spectator.preferences.scale_to_fit")}
        </FormLabel>
        <HStack>
          <Switch
            id="scale-to-fit"
            isChecked={interactiveSpectatorPreferences.scaleToFit}
            size="sm"
            onChange={(event) => {
              setInteractiveSpectatorPreferences((value) => ({
                ...value,
                scaleToFit: event.target.checked,
              }));
            }}
            alignSelf={"flex-start"}
            paddingTop={1}
          />
          <Stack spacing={0}>
            <FormLabel htmlFor="scale-to-fit" mb={0}>
              {interactiveSpectatorPreferences.scaleToFit
                ? t(
                    "streamer.interactive_spectator.preferences.scale_to_fit_enabled",
                  )
                : t(
                    "streamer.interactive_spectator.preferences.scale_to_fit_disabled",
                  )}
            </FormLabel>
            <FormHelperText marginTop={0}>
              {t(
                "streamer.interactive_spectator.preferences.scale_to_fit_help",
              )}
            </FormHelperText>
          </Stack>
        </HStack>
      </FormControl>
    </Stack>
  );
}
