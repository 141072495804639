import { Box, Divider, HStack, Heading, Stack, Text } from "@chakra-ui/react";
import dayjs from "dayjs";
import LocalizedFormat from "dayjs/plugin/localizedFormat";
import { Helmet } from "react-helmet";
import { default as Markdown } from "react-markdown";
import { Navigate, generatePath, useParams } from "react-router-dom";
import {
  AppActions,
  ApplicationImageCarousel,
  ApplicationQueryError,
  BrandedBadge,
  BrandedSkeleton,
} from "../components";
import { useActiveOrganizationQuery, usePortalName } from "../hooks";
import { useApplicationBuildQuery } from "../hooks/useApplicationBuildsQuery";
import { useApplicationCategoryQuery } from "../hooks/useApplicationCategoriesQuery";
import { useApplicationQuery } from "../hooks/useApplicationsQuery";
import { renderer } from "../theme/markdown";
import { ApplicationChangelogs } from "./details/ApplicationChangelogs";
import { RelatedApps } from "./details/RelatedApps";

dayjs.extend(LocalizedFormat);

type DetailPageParams = {
  id?: string;
  groupId?: string;
};

export function DetailPage() {
  const productName = usePortalName();
  const { id: applicationId, groupId } = useParams<DetailPageParams>();
  const isApplicationBuildId = !isNaN(Number(applicationId));

  // if application Id is a number, it's actually an application build's id, not an application id which would be a UUID
  // in this case, we need to find the underlying application id
  const applicationBuildId = Number(applicationId);
  const applicationBuildQuery = useApplicationBuildQuery(applicationBuildId, {
    throwOnError: true,
    enabled: isApplicationBuildId,
  });

  const applicationGroupId = Number(groupId) || 0;
  const { data: organization } = useActiveOrganizationQuery();
  const { data: groupData, isSuccess: groupDataLoaded } =
    useApplicationCategoryQuery(applicationGroupId, organization);

  const {
    isError,
    error,
    data: app,
    isPlaceholderData,
    isSuccess,
  } = useApplicationQuery(applicationId!, {
    enabled: !isApplicationBuildId,
    placeholderData: {
      id: "00000000-0000-0000-0000-000000000000",
      legacy_identity: "0",
      name: "Loading",
      tags: ["test1", "test2", "test3"],
      description: "Some placeholder text",
      launch_configurations: [],
      images: [],
      permissions: {},
      panoramic_preview_image: null,
      modified_date: new Date().toISOString(),
      oauth2_client_id: null,
      description_html: "",
      organizations: [],
    },
  });
  const isLoaded = isSuccess && !isPlaceholderData;

  if (!applicationId) {
    return <Navigate to="/" />;
  }

  // redirect to the application page if a build id was provided
  if (isApplicationBuildId && applicationBuildQuery.isSuccess) {
    return (
      <Navigate
        to={generatePath(groupId ? "/groups/:groupId/apps/:id" : "/apps/:id", {
          id: applicationBuildQuery.data.application,
          groupId: groupId ?? null,
        })}
      />
    );
  }

  if (isError) {
    return (
      <Box
        flexGrow={1}
        alignItems={"center"}
        alignSelf={"center"}
        display={"flex"}
        maxW={"container.sm"}
        padding={4}
      >
        <ApplicationQueryError error={error} />
      </Box>
    );
  }

  return (
    <Box>
      <Helmet>
        <title>
          {app?.name ?? ""}
          {app?.name ? " - " : ""}
          {productName}
        </title>
      </Helmet>
      <Stack
        position="relative"
        padding={[4, 6]}
        flexDirection={{ base: "column-reverse", md: "row" }}
      >
        <Stack
          position="relative"
          zIndex={2}
          px={4}
          paddingLeft="0"
          flexBasis="40%"
          alignSelf="flex-start"
          paddingTop="20px"
          justifyContent="center"
          spacing={8}
        >
          <Stack>
            {applicationGroupId > 0 && (
              <BrandedSkeleton isLoaded={groupDataLoaded}>
                <Heading as="h3" size="sm" fontWeight="bold">
                  {groupData?.name}
                </Heading>
              </BrandedSkeleton>
            )}
            <BrandedSkeleton isLoaded={isLoaded}>
              <Heading as="h2" size="xl">
                {app?.name}
              </Heading>
            </BrandedSkeleton>
            <HStack flexWrap={"wrap"} rowGap={0}>
              {app?.tags.map((tag, idx) => (
                <BrandedSkeleton isLoaded={isLoaded} key={idx}>
                  <BrandedBadge variant="subtle" colorScheme="gray">
                    {tag}
                  </BrandedBadge>
                </BrandedSkeleton>
              ))}
            </HStack>
          </Stack>
          <AppActions applicationId={applicationId} />
          {!!app?.description && (
            <Box>
              <BrandedSkeleton isLoaded={isLoaded}>
                <Text as={Box}>
                  <Markdown components={renderer}>{app?.description}</Markdown>
                </Text>
              </BrandedSkeleton>
            </Box>
          )}
          {isSuccess && (
            <>
              <Divider />
              <ApplicationChangelogs app={app} />
            </>
          )}
        </Stack>

        <Box overflow="hidden" flexBasis="60%">
          {app && (
            <ApplicationImageCarousel
              applicationData={app}
              maxHeight={{ base: "xs", md: "md" }}
              minHeight={{ base: "xs", md: "sm" }}
            />
          )}
        </Box>
      </Stack>
      <Box marginTop={[10]}>
        <RelatedApps
          applicationCategoryId={applicationGroupId}
          applicationId={applicationId}
        />
      </Box>
    </Box>
  );
}
