import { useLocalStorage } from "usehooks-ts";

export type InteractiveSpectatorPreferences = {
  scaleToFit: boolean;
};

export function useInteractiveSpectatorPreferences() {
  return useLocalStorage<InteractiveSpectatorPreferences>(
    "interactiveSpectatorPreferences",
    {
      scaleToFit: true,
    },
  );
}
