import { useAuthentication } from "../auth";
import { selectSessionState } from "../features/sessionSlice";
import { useAppSelector } from "../hooks";
import { isMobile } from "../utils/browser-support";

export function useIsDcvAvailable() {
  const { user_permissions } = useAuthentication();
  const session = useAppSelector(selectSessionState);
  return (
    user_permissions?.includes("core.remote_rendering.use_dcv") &&
    !!session.streamingGatewayEndpoint &&
    !isMobile
  );
}
