import { createContext, useContext } from "react";
import { createEnumParam } from "serialize-query-params";
import { Application, ApplicationBuildId, Organization } from "../hooks/types";
import { ClientDevice, SessionType } from "../session/types";
import { StreamerType } from "../streamer/utils";

export const SessionTypeEnumParam = createEnumParam([
  SessionType.CloudRenderedNonVR,
  SessionType.CloudRenderedVR,
  SessionType.LocallyRenderedStandalone,
  SessionType.LocallyRenderedWindows,
]);

export type LaunchPageContextProps = {
  // launch configurations which support the requested render mode and target device type
  applicationBuildId: ApplicationBuildId;
  application: Application;
  organization: Organization;
  launchArgs: string;
  forceColdVm: boolean;
  encryptVrStream: boolean;
  vmImage?: string;
  vmSize?: string;
  renderRegion?: string;
  targetDevice?: ClientDevice;
  debugModeEnabled: boolean;
  virtualMachineId?: string;
  streamer: StreamerType;
};
export const LaunchPageContext = createContext<LaunchPageContextProps>(
  {} as LaunchPageContextProps,
);

export const useLaunchPageContext = () => useContext(LaunchPageContext);
