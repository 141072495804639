import { CircularProgress, Flex, useDisclosure } from "@chakra-ui/react";
import { useEffect } from "react";
import { Helmet } from "react-helmet";
import { generatePath, Navigate } from "react-router-dom";
import { useQueryParam, withDefault } from "use-query-params";
import { ConfirmSessionAbortDialog } from "../components";
import { useDcvPreference } from "../components/useDcvPreference";
import {
  selectSessionOverviewReceived,
  selectSessionState,
} from "../features/sessionSlice";
import {
  useActiveOrganizationQuery,
  useAppSelector,
  usePortalName,
} from "../hooks";
import { useApplicationForApplicationBuildQuery } from "../hooks/useApplicationBuildsQuery";
import { SESSION_STATE } from "../session/session-state";
import { terminateSession } from "../signalR";
import { Streamer } from "../streamer/Streamer";
import { StreamerContextProvider } from "../streamer/StreamerContext";
import { StreamerTypeEnumParam } from "../streamer/utils";
import { routes } from "../utils/routes";
import { SessionFromDifferentOrganizationWarning } from "./session-page/SessionFromDifferentOrganizationWarning";
import { SessionPageContext } from "./session-page/SessionPageContext";
import { SessionPageHeader } from "./session-page/SessionPageHeader";
import { SessionPreview } from "./session-page/SessionPreview";
import { useIsDcvAvailable } from "./useIsDcvAvailable";
import { useIsReadyToStream } from "./useIsReadyToStream";

export function SessionPage() {
  const { data: organization } = useActiveOrganizationQuery();
  const session = useAppSelector(selectSessionState);
  const sessionOverviewReceived = useAppSelector(selectSessionOverviewReceived);
  const abortSessionDialogState = useDisclosure();
  const productName = usePortalName();
  const { data: app } = useApplicationForApplicationBuildQuery(
    session.applicationBuildId,
  );
  const useDcvIfAvailable = useDcvPreference();
  const [streamerType, setStreamerType] = useQueryParam(
    "streamer",
    withDefault(
      StreamerTypeEnumParam,
      useDcvIfAvailable ? ("dcv" as const) : ("innoactive" as const),
    ),
  );
  const isReadyToStream = useIsReadyToStream();
  const isDcvAvailable = useIsDcvAvailable();

  // reset streamer type if dcv is not available
  useEffect(() => {
    if (isReadyToStream && (!isDcvAvailable || !useDcvIfAvailable)) {
      setStreamerType("innoactive");
    }
  }, [useDcvIfAvailable, isDcvAvailable, isReadyToStream, setStreamerType]);

  const abortSession = () => {
    terminateSession(session.id!);
  };

  if (!sessionOverviewReceived) {
    return (
      <Flex justifyContent={"center"} alignItems={"center"} flexGrow={1}>
        <CircularProgress isIndeterminate />
      </Flex>
    );
  }

  // if we ended the session manually (or it errored), redirect back to the application's detail page
  if (session.state >= SESSION_STATE.ENDING && app) {
    return (
      <Navigate to={generatePath(routes.application.details, { id: app.id })} />
    );
  } else if (session.state < SESSION_STATE.REQUESTED) {
    return <Navigate to={routes.home} />;
  }

  // if the session is from another organization, show a warning and allow to end the session
  if (
    organization &&
    session.id &&
    Number(session.organizationId) !== organization?.id
  ) {
    return (
      <SessionFromDifferentOrganizationWarning
        abortSession={abortSession}
        organizationId={Number(session.organizationId)}
      />
    );
  }

  return (
    <Flex height="full" width="full" flexDirection={"column"}>
      <Helmet>
        <title>
          Session {app?.name ?? ""}
          {app?.name ? " - " : ""}
          {productName}
        </title>
      </Helmet>
      <StreamerContextProvider>
        <SessionPageHeader
          session={session}
          streamerType={streamerType}
          setStreamerType={setStreamerType}
          app={app}
          onAbortSession={abortSessionDialogState.onOpen}
        />

        <SessionPageContext.Provider
          value={{
            showAbortSessionDialog: abortSessionDialogState.onOpen,
            abortSession,
          }}
        >
          <Flex
            position="relative"
            overflow="hidden"
            flexGrow="1"
            minHeight={["20vh", "30vh", undefined] as unknown as string}
            flexDirection="column"
          >
            {isReadyToStream ? (
              <Streamer streamerType={streamerType} />
            ) : (
              <SessionPreview session={session} />
            )}
          </Flex>
          <ConfirmSessionAbortDialog
            isOpen={abortSessionDialogState.isOpen}
            onClose={abortSessionDialogState.onClose}
            onConfirm={abortSession}
          />
        </SessionPageContext.Provider>
      </StreamerContextProvider>
    </Flex>
  );
}
