import axios from "axios";
import queryString from "query-string";
import { UserDetails } from "../auth";
import type { PermissionString as PlatformPermission } from "../auth/types";
import { portalBackendUrl } from "../config";
import type {
  Application,
  ApplicationBuild,
  ApplicationBuildId,
  ApplicationBuildListFilters,
  ApplicationCategory,
  ApplicationCloudRenderingConfiguration,
  ApplicationExternalReference,
  ApplicationId,
  ApplicationLaunchConfiguration,
  ApplicationListFilters,
  Organization,
  PaginatedApplicationBuildsList,
  PaginatedApplicationsList,
  PortalBackendPaginatedResult,
  PortalPrivacyAndTerms,
} from "../hooks/types";

const portalApiClient = axios.create({
  baseURL: portalBackendUrl,
});

const genericPortalApiClient = axios.create({
  baseURL: portalBackendUrl,
});

export default portalApiClient;
export { genericPortalApiClient };

type QueryParams = { [key: string]: unknown };

export async function getApplicationCategory(
  id: number,
  queryParams: QueryParams = {},
) {
  const response = await portalApiClient.get<ApplicationCategory>(
    `/api/v2/application-categories/${id}/`,
    { params: queryParams },
  );
  return response.data;
}

export async function getApplicationBuildsPaginated(
  filters: ApplicationBuildListFilters = { page_size: 10 },
) {
  return (
    await portalApiClient.get<PaginatedApplicationBuildsList>(
      `/api/v2/application-builds/`,
      {
        params: filters,
        paramsSerializer: (params) => queryString.stringify(params),
      },
    )
  ).data;
}

export async function getApplicationBuild(id: ApplicationBuildId) {
  return (
    await portalApiClient.get<ApplicationBuild>(
      `/api/v2/application-builds/${id}/`,
    )
  ).data;
}

type PrivacyAndTermsListResponse =
  PortalBackendPaginatedResult<PortalPrivacyAndTerms>;

export async function fetchPrivacyAndTerms(params: Record<string, unknown>) {
  const response = await portalApiClient.get<PrivacyAndTermsListResponse>(
    `/api/privacy/`,
    { params },
  );

  if (response.data.results.length > 0) {
    return response.data.results[0];
  }
  throw Error("No privacy and terms found");
}

export async function verifyDeviceAuthorizationRequest(
  userCode: string,
  organization?: Organization,
) {
  return await portalApiClient.post("/oauth/device_authorization/verify/", {
    user_code: userCode,
    organization: organization?.id,
  });
}

interface ApplicationAccessRequest {
  created_date: string;
  message: string;
}

export async function fetchApplicationAccessRequest(
  applicationId: ApplicationId,
  queryParams: QueryParams = {},
) {
  const response = await portalApiClient.get<ApplicationAccessRequest>(
    `/api/v2/applications/${applicationId}/request-access/`,
    {
      params: queryParams,
    },
  );
  return response.data;
}

export async function createApplicationAccessRequest(
  applicationId: ApplicationId,
  organizationId: Organization["id"],
  message: string,
) {
  return await portalApiClient.post<ApplicationAccessRequest>(
    `/api/v2/applications/${applicationId}/request-access/`,
    {
      message,
    },
    {
      params: { organization: organizationId },
    },
  );
}

export async function getOrganizationDetails(subdomain: string) {
  return (
    await portalApiClient.get<Organization>(
      `/api/organizations/by-subdomain/${subdomain}/`,
    )
  ).data;
}

export async function getOrganizationDetailsById(id: Organization["id"]) {
  return (
    await genericPortalApiClient.get<Organization>(`/api/organizations/${id}/`)
  ).data;
}

export async function getCurrentUserDetails() {
  return (await genericPortalApiClient.get<UserDetails>("/api/users/current/?embed_permissions"))
    .data;
}

export async function getUserPlatformPermissions(
  userId: number,
  organizationId: number,
) {
  return (
    await portalApiClient.get<PlatformPermission[]>(
      `/api/users/${userId}/platform-permissions/`,
      { params: { organization: organizationId } },
    )
  ).data;
}

export async function getApplicationExternalReferences(
  applicationId: ApplicationId,
  filters: { type?: string } = {},
) {
  return (
    await portalApiClient.get<
      PortalBackendPaginatedResult<ApplicationExternalReference>
    >(`/api/v2/applications/${applicationId}/external-references/`, {
      params: filters,
    })
  ).data;
}

export async function getApplication(applicationId: ApplicationId | undefined) {
  return (
    await portalApiClient.get<Application>(
      `/api/v2/applications/${applicationId}/`,
    )
  ).data;
}

export async function getApplicationsPaginated(
  filters: ApplicationListFilters = { page_size: 10 },
) {
  return (
    await portalApiClient.get<PaginatedApplicationsList>(
      "/api/v2/applications/",
      {
        params: filters,
        paramsSerializer: (params) => {
          // we need comma-separated stringification for `id_in`
          const { id_in, ...rest } = params;
          return [
            queryString.stringify({ id_in }, { arrayFormat: "comma" }),
            queryString.stringify(rest),
          ].join("&");
        },
      },
    )
  ).data;
}

export async function getLaunchConfigurationsForBuild(
  applicationBuildId: ApplicationBuildId,
) {
  return (
    await portalApiClient.get<
      PortalBackendPaginatedResult<
        Pick<ApplicationLaunchConfiguration, "application" | "xr_platform">
      >
    >(`/api/v2/application-builds/${applicationBuildId}/launch-configurations/`)
  ).data;
}

export async function getApplicationCloudRenderingConfiugration(
  applicationId: ApplicationId,
) {
  return (
    await portalApiClient.get<ApplicationCloudRenderingConfiguration>(
      `/api/v2/applications/${applicationId}/cloud-rendering-configuration/`,
    )
  ).data;
}
