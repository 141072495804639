import {
  QueryClient,
  UseQueryOptions,
  useInfiniteQuery,
  useQuery,
  useQueryClient
} from "@tanstack/react-query";
import { AxiosError } from "axios";
import queryString from "query-string";
import {
  getApplication,
  getApplicationCloudRenderingConfiugration,
  getApplicationsPaginated,
} from "../portal-api";
import {
  Application,
  ApplicationCloudRenderingConfiguration,
  ApplicationId,
  ApplicationListFilters,
  PaginatedApplicationsList,
  PortalError,
} from "./types";

export const baseQueryKeyForApplications = ["applications"] as const;

export function getQueryKeyForApplication(id: ApplicationId | undefined) {
  return [...baseQueryKeyForApplications, id ?? null] as const;
}

export function getQueryKeyForApplications(filters?: ApplicationListFilters) {
  if (!filters) {
    return baseQueryKeyForApplications;
  }

  // if search text is empty, don't send it to the backend
  filters = {
    ...filters,
    fulltext_search: filters?.fulltext_search
      ? filters.fulltext_search
      : undefined,
  };

  return [...baseQueryKeyForApplications, filters];
}

function getQueryForApplications<T = PaginatedApplicationsList>(
  queryClient: QueryClient,
  filters?: ApplicationListFilters,
  queryOptions?: Partial<
    UseQueryOptions<PaginatedApplicationsList, AxiosError<PortalError>, T>
  >,
): UseQueryOptions<PaginatedApplicationsList, AxiosError<PortalError>, T> {
  return {
    // eslint-disable-next-line @tanstack/query/exhaustive-deps
    queryKey: getQueryKeyForApplications(filters),
    queryFn: async () => {
      const applicationsResponse = await getApplicationsPaginated(filters);
      // prepopulate the cache with the first page of applications
      populateApplicationDetailsQueryCache(
        queryClient,
        applicationsResponse.results,
      );
      return applicationsResponse;
    },
    ...queryOptions,
  };
}

export function useApplicationsQuery<T = PaginatedApplicationsList>(
  filters?: ApplicationListFilters,
  queryOptions: Partial<
    UseQueryOptions<PaginatedApplicationsList, AxiosError<PortalError>, T>
  > = {},
) {
  const queryClient = useQueryClient();
  return useQuery(getQueryForApplications(queryClient, filters, queryOptions));
}

export function getQueryForApplication(
  id: ApplicationId | undefined,
  queryOptions: Partial<UseQueryOptions<Application, AxiosError>> = {},
) {
  return {
    queryKey: getQueryKeyForApplication(id),
    queryFn: () => getApplication(id),
    ...queryOptions,
    enabled: !!id && (queryOptions.enabled ?? true),
  };
}

export function useApplicationQuery(
  id: ApplicationId | undefined,
  queryOptions: Partial<UseQueryOptions<Application, AxiosError>> = {},
) {
  return useQuery(getQueryForApplication(id, queryOptions));
}

function populateApplicationDetailsQueryCache(
  queryClient: ReturnType<typeof useQueryClient>,
  applications: Application[],
) {
  applications.forEach((application) => {
    queryClient.setQueryData(
      getQueryKeyForApplication(application.id),
      application,
    );
  });
}

export function useApplicationCloudRenderingConfigurationQuery(
  applicationId: ApplicationId | undefined,
  queryOptions: Partial<
    UseQueryOptions<
      ApplicationCloudRenderingConfiguration,
      AxiosError<PortalError>
    >
  > = {},
) {
  return useQuery<
    ApplicationCloudRenderingConfiguration,
    AxiosError<PortalError>
  >({
    queryKey: [
      ...getQueryKeyForApplication(applicationId),
      "cloud-rendering-configuration",
    ],
    queryFn: () =>
      getApplicationCloudRenderingConfiugration(applicationId ?? ""),
    ...queryOptions,
    enabled: !!applicationId && (queryOptions.enabled ?? true),
  });
}

export function usePaginatedApplicationsQuery(
  filters?: ApplicationListFilters,
  options?: { enabled: boolean },
) {
  const queryClient = useQueryClient();

  // fetch 10 elements per page by default
  filters = { ...filters, page_size: 10 };

  return useInfiniteQuery({
    queryKey: getQueryKeyForApplications(filters),
    queryFn: async ({ pageParam }) => {
      const applicationsResponse = await getApplicationsPaginated({
        ...filters,
        page: pageParam,
      });
      // prepopulate the cache with the first page of applications
      populateApplicationDetailsQueryCache(
        queryClient,
        applicationsResponse.results,
      );
      return applicationsResponse;
    },
    initialPageParam: 1,
    getNextPageParam: (lastPage) =>
      lastPage.next
        ? Number(queryString.parseUrl(lastPage.next).query.page)
        : undefined,
    getPreviousPageParam: (firstPage) =>
      firstPage.next
        ? Number(queryString.parseUrl(firstPage.next).query.page)
        : undefined,
    ...options,
  });
}
