import {
  Button,
  Icon,
  Menu,
  MenuButton,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  Text,
} from "@chakra-ui/react";
import { t } from "i18next";
import { DropdownIcon } from "../../components/icons";
import { StreamerType } from "../../streamer/utils";

export function StreamingProtocolSwitcher({
  streamerType,
  setStreamerType,
}: {
  streamerType: StreamerType;
  setStreamerType: (streamerType: StreamerType) => void;
}) {
  return (
    <Menu closeOnSelect={true} closeOnBlur={true}>
      <MenuButton
        as={Button}
        size="sm"
        variant={"ghost"}
        rightIcon={<Icon as={DropdownIcon} boxSize={3} />}
      >
        {streamerType === "dcv"
          ? t("session.streaming_technology.dcv")
          : t("session.streaming_technology.innoactive")}
      </MenuButton>
      <MenuList minWidth="240px">
        <MenuOptionGroup
          value={streamerType}
          onChange={(value) => setStreamerType(value as StreamerType)}
          title={t("session.streaming_technology.title")}
          type="radio"
        >
          <MenuItemOption value="dcv">
            DCV{" "}
            <Text as="span" color="chakra-subtle-text" fontSize={"small"}>
              (TCP)
            </Text>
          </MenuItemOption>
          <MenuItemOption value="innoactive">
            Innoactive{" "}
            <Text as="span" color="chakra-subtle-text" fontSize={"small"}>
              (WebRTC)
            </Text>
          </MenuItemOption>
        </MenuOptionGroup>
      </MenuList>
    </Menu>
  );
}
