import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Button,
  Heading,
  SkeletonText,
  Stack,
} from "@chakra-ui/react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { BrandedSkeleton } from "../../components";
import { ApplicationBuildId } from "../../hooks/types";
import {
  useApplicationBuildQuery,
  useInfiniteApplicationBuildsQuery,
} from "../../hooks/useApplicationBuildsQuery";
import { ApplicationBuildChangelog } from "./ApplicationBuildChangelog";

export function LazyApplicationBuildChangelog({
  buildId,
}: {
  buildId: ApplicationBuildId;
}) {
  const { t } = useTranslation();
  const [showOlderChangelogs, setShowOlderChangelogs] = useState(false);
  const applicationBuildQuery = useApplicationBuildQuery(buildId, {
    retry: false,
    throwOnError: true,
  });
  // fetch changelogs of older versions as well
  const infiniteApplicationBuildsQuery = useInfiniteApplicationBuildsQuery({
    application: applicationBuildQuery.data?.application,
    supported_xr_platform: applicationBuildQuery.data?.supported_xr_platforms,
    ordering: "-version",
  });

  if (applicationBuildQuery.isLoading) {
    return (
      <Stack spacing={4}>
        <BrandedSkeleton>
          <Heading size="xs">Changelog loading</Heading>
        </BrandedSkeleton>
        <BrandedSkeleton>
          <SkeletonText noOfLines={3} spacing={2} />
        </BrandedSkeleton>
      </Stack>
    );
  }

  if (applicationBuildQuery.isError) {
    return (
      <Alert status="error">
        <AlertIcon />
        <AlertTitle>Error!</AlertTitle>
        <AlertDescription>
          {(applicationBuildQuery.error as Error).message}
        </AlertDescription>
      </Alert>
    );
  }

  if (applicationBuildQuery.isSuccess) {
    return (
      <Stack spacing={4}>
        <Heading size="sm">{t("details.changelog")}</Heading>
        <ApplicationBuildChangelog build={applicationBuildQuery.data} />
        {showOlderChangelogs &&
          infiniteApplicationBuildsQuery.data?.pages.map((page) =>
            page.results
              .filter((build) => build.id !== applicationBuildQuery.data.id)
              .map((build) => (
                <ApplicationBuildChangelog key={build.id} build={build} />
              ))
              .flat(),
          )}
        {(((infiniteApplicationBuildsQuery.data?.pages[0].count ?? 0) > 1 &&
          !showOlderChangelogs) ||
          infiniteApplicationBuildsQuery.hasNextPage) && (
          <Button
            variant={"ghost"}
            size="xs"
            onClick={() => {
              if (!showOlderChangelogs) setShowOlderChangelogs(true);
              else infiniteApplicationBuildsQuery.fetchNextPage();
            }}
            alignSelf={"start"}
          >
            {t("details.changelog_show_more")}
          </Button>
        )}
      </Stack>
    );
  }

  return null;
}
