import {
  Alert,
  AlertIcon,
  AlertTitle,
  ChakraProvider,
  Flex,
} from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import { ReactNode, useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  AuthContext,
  AuthProvider,
  IAuthContext,
} from "react-oauth2-code-pkce";
import { getOrganizationSelectionUrl } from ".";
import { ColorModeConfiguration } from "../ColorModeConfiguration";
import { ExternalRedirect } from "../components/ExternalRedirect";
import { portalBackendUrl } from "../config";
import { useActiveOrganizationQuery } from "../hooks";
import { useUserPlatformPermissionsQuery } from "../hooks/useUserPlatformPermissionsQuery";
import portalApiClient, {
  genericPortalApiClient,
  getCurrentUserDetails,
} from "../portal-api";
import sessionManagementApiClient from "../session-management-api";
import { AuthenticationContext } from "./AuthenticationContext";
import { useAuthConfig } from "./useAuthConfig";

export function AuthenticationProvider({ children }: { children: ReactNode }) {
  const {
    error,
    token,
    logIn: login,
    loginInProgress,
  }: IAuthContext = useContext(AuthContext);
  const { data: organization } = useActiveOrganizationQuery();

  useEffect(() => {
    if (!token) return;

    // ensure the token is sent with requests to portal and sm api
    [
      portalApiClient,
      genericPortalApiClient,
      sessionManagementApiClient,
    ].forEach((apiClient) => {
      apiClient.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    });

    // handle 401s
    const myInterceptor = portalApiClient.interceptors.response.use(
      (response) => response,
      async (error) => {
        // If we're unauthorized, user must login again (but only do this if not yet refreshing)
        if (error?.response?.status === 401) {
          login();
        }
        throw error;
      },
    );

    return () => {
      portalApiClient.interceptors.response.eject(myInterceptor);
    };
  }, [token, login]);

  // send organization id with requests to session management api
  useEffect(() => {
    sessionManagementApiClient.defaults.headers.common[
      "Portal-Organization-Id"
    ] = organization?.id;
  }, [organization?.id]);

  // make sure organization-speicifc requests go to the right domain
  useEffect(() => {
    if (!portalApiClient.defaults.baseURL) {
      return;
    }

    const currentBaseUrl = new URL(portalApiClient.defaults.baseURL); // throws if baseURL is not a valid URL
    currentBaseUrl.hostname =
      organization?.domain ?? new URL(portalBackendUrl).hostname;
    portalApiClient.defaults.baseURL = currentBaseUrl.toString();
  }, [organization?.domain]);

  const {
    data: user,
    error: loadUserDetailsError,
    isLoading: isPendingAuthentication,
    isSuccess: isUserSuccess,
  } = useQuery({
    queryKey: ["current-user"],
    queryFn: getCurrentUserDetails,
    enabled: !!token && !!organization,
    retry: false,
  });

  const { data: userPlatformPermissions } = useUserPlatformPermissionsQuery(
    user?.id,
    organization?.id,
    {
      enabled: !!user && !!organization,
    },
  );
  const isUserPartOfOrganization =
    user &&
    organization &&
    user.organizations.find((org) => org.id === organization.id);

  if (isUserSuccess && !isUserPartOfOrganization) {
    // the user is not part of the active organization, go to backend to select one the user is actually part of
    return (
      <ExternalRedirect
        url={getOrganizationSelectionUrl(window.location.pathname)}
      />
    );
  }

  return (
    <AuthenticationContext.Provider
      value={{
        token,
        error: loadUserDetailsError?.message ?? error ?? undefined,
        user,
        user_permissions: userPlatformPermissions ?? [],
        isPendingAuthentication: isPendingAuthentication || loginInProgress,
      }}
    >
      {children}
    </AuthenticationContext.Provider>
  );
}

export function AuthProviderWrapper({ children }: { children: ReactNode }) {
  const { data: authConfig, error } = useAuthConfig();
  const { t } = useTranslation();

  if (error && error.code !== "ECONNABORTED" && !authConfig) {
    // redirect to portal backend to identify organization and then come back to frontend afterwards.
    let redirectUrl = window.location.pathname;
    if (window.location.search) {
      redirectUrl += "?" + window.location.search;
    }
    return <ExternalRedirect url={getOrganizationSelectionUrl(redirectUrl)} />;
  }

  if (!authConfig) {
    return (
      <ChakraProvider>
        <ColorModeConfiguration />
        <Flex w="100vw" h="100vh" alignItems="center" justifyContent="center">
          <Alert status="loading" maxW={"container.sm"}>
            <AlertIcon />
            <AlertTitle>{t("login.identifying_organization")}</AlertTitle>
          </Alert>
        </Flex>
      </ChakraProvider>
    );
  }

  return <AuthProvider authConfig={authConfig}>{children}</AuthProvider>;
}
