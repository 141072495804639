import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { ApplicationGroup } from "../../components";
import { useActiveOrganizationQuery } from "../../hooks";
import { ApplicationCategory, ApplicationId } from "../../hooks/types";
import { useApplicationCategoryQuery } from "../../hooks/useApplicationCategoriesQuery";
import { useApplicationsQuery } from "../../hooks/useApplicationsQuery";

export function RelatedApps({
  applicationCategoryId,
  applicationId,
}: {
  applicationCategoryId: ApplicationCategory["id"];
  applicationId: ApplicationId;
}) {
  const { data: organization } = useActiveOrganizationQuery();
  const { t } = useTranslation();

  const { data: groupData } = useApplicationCategoryQuery(
    applicationCategoryId,
    organization,
  );
  const { data: appPageData } = useApplicationsQuery();

  const relatedApplicationIds = useMemo(() => {
    if (!groupData) return [];

    if (groupData.applications?.length > 0) {
      return groupData?.applications;
    }

    if (!appPageData) return [];

    const applicationIds = appPageData.results.map((app) => app.id);
    if (applicationIds?.length <= 3) {
      return [];
    }
    if (applicationIds?.length < 6) {
      return applicationIds?.slice(0, 3);
    }

    const randomIndex =
      Math.floor(Math.random() * (applicationIds?.length - 2)) + 0;

    return applicationIds?.slice(randomIndex, randomIndex + 3);
  }, [appPageData, groupData]);

  return (
    <>
      {relatedApplicationIds?.length > 0 && (
        <ApplicationGroup
          appIds={
            relatedApplicationIds.filter(
              (_applicationId) => _applicationId !== applicationId,
            ) || []
          }
          title={t("details.related_apps")}
        />
      )}
    </>
  );
}
