import { useAuthentication } from "../auth";
import { useFeatureFlags } from "../hooks";

export function useDcvPreference() {
  const { user_permissions } = useAuthentication();
  const [{ useDcvForBrowserStreaming = false }] = useFeatureFlags();

  return (
    useDcvForBrowserStreaming &&
    user_permissions?.includes("core.remote_rendering.use_dcv")
  );
}
