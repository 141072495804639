import {
  Heading,
  HStack,
  Spacer,
  Stack,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import Markdown from "react-markdown";
import { ApplicationBuild } from "../../hooks/types";
import { renderer } from "../../theme/markdown";

export function ApplicationBuildChangelog({
  build,
}: {
  build: ApplicationBuild;
}) {
  const { t } = useTranslation();

  return (
    <Stack
      spacing={4}
      color={!build.changelog ? "chakra-subtle-text" : undefined}
    >
      <Heading size="xs" as="h3">
        <HStack>
          <Text>{build.version}</Text>
          <Spacer />
          <Tooltip label={dayjs(build.created_date).format("LLLL")}>
            <Text>{dayjs(build.created_date).fromNow()}</Text>
          </Tooltip>
        </HStack>
      </Heading>
      <Markdown components={renderer}>
        {build.changelog || t("details.no_changelog_available")}
      </Markdown>
    </Stack>
  );
}
