import {
  Box,
  ButtonGroup,
  Divider,
  Heading,
  HStack,
  Icon,
  IconButton,
  Link,
  Spacer,
  Stack,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import { Trans, useTranslation } from "react-i18next";
import { Link as RouterLink } from "react-router-dom";
import { ActiveSessionButton, BrandedSkeleton } from "../../components";
import { ClearIcon, WarningIcon } from "../../components/icons";
import { Logo } from "../../components/Logo";
import { selectSessionDevice } from "../../features/devicesSlice";
import { SessionDataExtended } from "../../features/sessionSlice";
import {
  useAppSelector,
  useClientApplicationVersionInformation,
} from "../../hooks";
import { Application } from "../../hooks/types";
import { DcvPreferencesButton } from "../../streamer/dcv/DcvPreferencesButton";
import { InteractiveSpectatorPreferencesButton } from "../../streamer/interactive-spectator/InteractiveSpectatorPreferencesButton";
import { StreamerControls } from "../../streamer/StreamerControls";
import { type StreamerType } from "../../streamer/utils";
import { useIsDcvAvailable } from "../useIsDcvAvailable";
import { useIsReadyToStream } from "../useIsReadyToStream";
import { StreamingProtocolSwitcher } from "./StreamingProtocolSwitcher";

export function SessionPageHeader({
  session,
  app,
  streamerType,
  setStreamerType,
  onAbortSession,
}: {
  session: SessionDataExtended;
  app?: Application;
  streamerType: StreamerType;
  setStreamerType: (streamerType: StreamerType) => void;
  onAbortSession: () => void;
}) {
  const { t } = useTranslation();
  const activeDevice = useAppSelector(selectSessionDevice);
  const { isOutdated } = useClientApplicationVersionInformation(activeDevice);
  const isDcvAvailable = useIsDcvAvailable();
  const isReadyToStream = useIsReadyToStream();

  return (
    <Stack
      direction={{ base: "column", md: "row" }}
      width="full"
      padding={2}
      spacing={2}
      alignItems={{ base: "normal", md: "center" }}
      flexDirection={{ base: "column-reverse", md: "row" }}
    >
      <Link
        as={RouterLink}
        _focus={undefined}
        to="/"
        marginRight="6"
        variant={"unstyled"}
        display={{ base: "none", md: "block" }}
      >
        <Logo height={10} />
      </Link>
      <BrandedSkeleton
        isLoaded={!!app?.name || !session.id}
        display={{ base: "none", md: "block" }}
      >
        <Heading as="h3" size="xs">
          {app?.name || "Loading ..."}
        </Heading>
      </BrandedSkeleton>
      {session.isCloudRenderedVr ? (
        <BrandedSkeleton isLoaded={!!session.id}>
          <HStack>
            {activeDevice ? (
              <Text fontSize="xs" color={"chakra-subtle-text"}>
                <Trans
                  i18nKey="session.running_on"
                  values={{
                    name: activeDevice.name,
                  }}
                  components={{ bold: <strong /> }}
                />
              </Text>
            ) : null}
            {isOutdated ? (
              <Tooltip
                hasArrow
                label={`Client is outdated, please update soon.`}
              >
                <IconButton
                  aria-label="Outdated version warning"
                  icon={<Icon as={WarningIcon} />}
                  color="yellow.200"
                  variant="link"
                  size="xs"
                />
              </Tooltip>
            ) : null}
          </HStack>
        </BrandedSkeleton>
      ) : null}
      <Spacer display={{ base: "none", md: "block" }} />
      <HStack
        justifyContent={"space-between"}
        flexDirection={{ base: "row-reverse", md: "row" }}
      >
        {isReadyToStream ? (
          <>
            <Divider
              orientation="vertical"
              display={{ base: "none", md: "block" }}
            />
            <StreamerControls />
            <Divider
              orientation="vertical"
              display={{ base: "none", md: "block" }}
            />
          </>
        ) : null}
        <HStack>
          {isDcvAvailable ? (
            <>
              <Spacer />
              <Box flexGrow={1}>
                <StreamingProtocolSwitcher
                  streamerType={streamerType}
                  setStreamerType={setStreamerType}
                />
              </Box>
            </>
          ) : null}
          {isReadyToStream && (
            <>
              {streamerType === "dcv" && isDcvAvailable ? (
                <DcvPreferencesButton />
              ) : streamerType === "innoactive" ? (
                <InteractiveSpectatorPreferencesButton />
              ) : null}
              <Divider
                orientation="vertical"
                display={{ base: "none", md: "block" }}
              />
            </>
          )}
        </HStack>
      </HStack>
      <ButtonGroup isAttached size={"sm"}>
        <ActiveSessionButton flexGrow={[1, 1, 0]} />
        <IconButton
          onClick={onAbortSession}
          aria-label={t("session.end")}
          icon={<Icon as={ClearIcon} />}
          colorScheme="red"
          variant="solid"
        />
      </ButtonGroup>
    </Stack>
  );
}
